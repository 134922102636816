import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import PageTitle from "../components/page-title"
import "../style.css"

class AboutUs extends React.Component {
	render() {
		return (
			<Layout>
				<div className='ph3 ph4-ns pt3 pt0-ns'>
					<PageTitle title="About Us" />
					<div className='cf pb4'>
						<div className='w-100 w-70-ns pr6-ns fl'>
							<article>
								<section className='pt1 pb4 mb2'>
									<h3 className='fw4 mt0 f4 f3-ns arvo black-50 pa0'>Our Promise</h3>
									<p className='black-80 lh-copy pa0 ma0 f5 f4-ns'>At 610 Design Studio, we have one goal: to help your special day—whatever the occasion!—become a memory that lasts forever. Our lasercut offerings are custom-made for your celebration, and we hope that they'll serve as a reminder of that special day for years to come.</p>
								</section>
								<section className='bt bw1 b--black-05 pv4 mv2'>
									<h3 className='fw4 mt0 f4 f3-ns arvo black-50 pa0'>Houston Made</h3>
									<p className='black-90 lh-copy f5 f4-ns'>We owe a lot to this city. Houston is where we met, where we live, and where we create and lasercut every single one of the designs you see in our shop.</p>
									<p className='black-90 lh-copy f5 f4-ns ma0'>The city is our home, but also our name: 610 Design Studio is named after the great big loop that circles the heart of Houston. There's a lot to see (and eat!) inside the loop, and we're so proud to have our office here, too.</p>
								</section>
								<section className='bt bw1 b--black-05 pv4 mt2'>
									{/*<h3 className='arvo black-50 fw4 mt0 f3 f2-ns pa0'>*/}
									<h3 className='fw4 mt0 f4 f3-ns arvo black-50 pa0'>The Team</h3>
									<p className='black-80 lh-copy f5 f4-ns'>Hello! We're Matt and Xenia. We're the owners and creators of 610 Design Studio, and we're the same people you'll work with every step of the way when you order one of our products. If you have any questions for us, don't hesitate to <Link className='blue no-underline hover-near-black' to="/contact">get in touch</Link>.</p>
								</section>
							</article>
						</div>
						<div className='w-100 w-30-ns pr5-ns fl'>
							<img className='db mv4-ns rotate-2' src="/assets/cute_photo_test.jpg" />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default AboutUs;